import React from 'react';
import './Confirmation.css';
import Page from '../../components/page/Page';
import Wrapper from '../../components/wrapper/Wrapper';
import Card from '../../components/card/Card';
import {Typography} from '@material-ui/core'
import Image from '../../components/image/Image';
import {format, parseISO} from 'date-fns'
import confirmation from '../../assets/magic-sprout-confirmation.png'


export const Confirmation = ({invoice, customer, teacher}) => {
  return(
      <>
        <div style={{height: '87.5vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Image src={confirmation} sx={{transform: 'translateY(24px)',border: '8px solid rgb(154 134 235)', boxSizing: 'border-box', borderRadius: '100%', boxShadow: 'rgb(96 0 255) 0px 0px 0px 12px'}} width={'280px'} />
          </div>
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Card width={'420px'} boxShadow={'0px 0px 0px 16px #6cff83'} border={'8px solid #40eb80'}>
              <Typography variant={'h2'} align={'center'} style={{position: 'relative', zIndex: '8', color: 'rgb(96 0 255)'}}><b>Thank you!</b></Typography>
              <Typography variant={'h6'} align={'center'}><b>Your payment has been confirmed</b></Typography>
              <Typography variant={'subtitle2'} align={'center'}>Your first lesson is at <span style={{color: 'rgb(96 0 255)'}}><b>{format(parseISO(invoice.startingDates[0]), 'h:mm aa, EEEE do MMMM yyyy')}</b></span></Typography>
              { teacher &&
                <>
                  <Typography variant={'subtitle2'} align={'center'}>Your teacher's name will be <span style={{color: 'rgb(96 0 255)'}}><b>{teacher.name}</b></span></Typography>
                  <Typography variant={'subtitle2'} align={'center'}>Your teacher's Zoom URL is <span style={{color: 'rgb(96 0 255)'}}><b>{teacher.zoomURL}</b></span></Typography>
                </>
              }
              <Typography variant={'subtitle2'} align={'center'}>We will be in touch shortly to prepare you for your lesson</Typography>
            </Card>
          </div>
        </div>
      </>
    )
}

export default Confirmation
