import React from 'react'
import './PaymentDetails.css'
import {Typography} from '@material-ui/core'
import Image from '../../../components/image/Image';
import Column from '../../../components/column/Column';
import Card from '../../../components/card/Card';
import pose3 from '../../../assets/pose-3.png';
export const PaymentDetails = ({qty, frq, price}) => {
  return(
  <>
  <Card boxShadow={'0px 3px 4px 0px #b092beaa'}>
    <Typography variant={'h5'}>
      Order Details
    </Typography>
    <table class='payment-details'>
      <tr>
        <td>{qty} x Online English Lessons<br/><span style={{fontSize: '0.75em'}}>{frq} lessons per week</span></td>
        <td>{parseInt(price).toLocaleString('en-HK', { style: 'currency', currency: 'HKD' })}</td>
      </tr>
      <tr>
        <td>Total Fee</td>
        <td>{(parseInt(price)).toLocaleString('en-HK', { style: 'currency', currency: 'HKD' })}</td>
      </tr>
    </table>
  </Card>
  </>
)
}

export default PaymentDetails
