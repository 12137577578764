import React from 'react';
import RevolutCheckout from '@revolut/checkout'
import { handleConfirmInvoicePayment } from '../../../api';

export const Revolut = ({invoiceURL, order, handlePaymentSuccessful}) => {

  // React.useEffect(() => {

  // }, [order])

  const handleFormSubmit = async () => {
    const RC = await RevolutCheckout(order.token, 'prod');

    const revolutPaymentPopup = RC.payWithPopup({
      onSuccess() {
        // alert('success')
        // finishOrder(order.id);
        let payload = {
          invoiceURL: invoiceURL
        }
        handleConfirmInvoicePayment(payload).then(result => {
          // console.log(result);
          if(result.success) {
            handlePaymentSuccessful(true, result)
            revolutPaymentPopup.destroy();
          } else {
            // setIsLoading(false)
            // setErrMessage('Payment Failed');
          }
        }).catch((err) => {
          // setIsLoading(false);
          console.log(err)
        })
        // handlePaymentSuccessful(true, result)
      },
      onError() {
        // alert('error')
        // renewOrder(order.id);
      },
      onCancel() {
        // alert('cancel')
        // renewOrder(order.id);
      }
    });
  }
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
        <button className={'payment-btn'} onClick={handleFormSubmit}>Pay Now</button>
      </div>
    </>
  )
}


export default Revolut
