import React, {useState,useEffect} from 'react';
import './Payment.css';
import Confirmation from '../confirmation/Confirmation'
import Page from '../../components/page/Page';
import Wrapper from '../../components/wrapper/Wrapper';
import Column from '../../components/column/Column';
import Card from '../../components/card/Card';
import PaymentDetails from './payment-details/PaymentDetails'
import WrongInvoice from '../wrong-invoice/WrongInvoice'
import Stripe from './stripe/StripeContainer';
import Revolut from './revolut/Revolut';
import { Typography } from '@material-ui/core'
import { checkPaymentLink } from '../../api';
import Image from '../../components/image/Image';
import logo from '../../assets/magic-sprout-logo.png'

export const Payment = ({location}) => {

  const [invoice, setInvoice] = useState(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false)
  const [wrongInvoice, setWrongInvoice] = useState(false)
  const [invoiceAlreadyPaid, setInvoiceAlreadyPaid] = useState(false)
  const [returnedInvoice, setReturnedInvoice] = useState(null)
  const [returnedCustomer, setReturnedCustomer] = useState(null)
  const [returnedTeacher, setReturnedTeacher] = useState(null)
  const [backgroundActive, setBackgroundActive] = useState('#f3f0f9')

  const CURRENCY = 'HKD'

  useEffect(() => {
    checkPayment();
  }, [])

function checkPayment() {
    return checkPaymentLink(location.pathname.substring(1))
    .then(result => {
      console.log(result);
      if(result.invoice) {
        console.log(result.invoice);
        if(result.invoice.paymentReceived) {
          setInvoiceAlreadyPaid(true)
        } else {
          setInvoice(result.invoice)
        }
      } else {
        setBackgroundActive('#fff')
        setWrongInvoice(true)
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const handlePaymentSuccessful = (bool, returnedValue) => {
    setPaymentSuccessful(bool)
    setReturnedInvoice(returnedValue.invoice)
    setReturnedCustomer(returnedValue.customer)
    setBackgroundActive('#f3f0f9')
    if(returnedValue.teacher) {
      setReturnedTeacher(returnedValue.teacher)
    }
  }

  return (
    <Page background={backgroundActive}>
      <Wrapper>
        { invoice && !invoiceAlreadyPaid && !paymentSuccessful && !wrongInvoice &&
          <>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '100%', maxWidth: '520px'}}>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
              <Image src={logo} width={'180px'} />
              </div>
              <PaymentDetails
                qty={invoice.lessonQty}
                frq={invoice.lessonFrq}
                price={invoice.price}
              />
              {invoice && !invoice.paymentReceived && <Revolut order={{id: invoice.revolutOrderID, token: invoice.revolutOrderToken}} handlePaymentSuccessful={handlePaymentSuccessful} invoiceURL={location.pathname.substring(1)} />}
              {invoice && invoice.paymentReceived && 
              
              <p style={{'textAlign': 'center'}}>Payment Already Received</p>
              
              }
                {/*<Stripe
                name={'Magic Sprout'}
                description={'Making online education magical'}
                amount={parseInt(invoice.price)}
                currency={CURRENCY}
                invoiceURL={location.pathname.substring(1)}
                paymentSuccessful={(e, r) => handlePaymentSuccessful(e, r)}
                />*/}
            </div>
          </div>
          </>
        }
        {
          paymentSuccessful &&
          <Confirmation invoice={returnedInvoice} customer={returnedCustomer} teacher={returnedTeacher} />
        }
        {
          wrongInvoice &&
          <WrongInvoice />
        }
        {
          invoiceAlreadyPaid &&
          <Card><Typography>This invoice has already been paid!</Typography></Card>
        }
      </Wrapper>
    </Page>
  )
}

export default Payment;
